define('ember-cli-chart/components/ember-chart', ['exports', 'ember', 'ember-cli-chart/chart-data-updater'], function (exports, _ember, _emberCliChartChartDataUpdater) {
  'use strict';

  /* global Chart */

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'canvas',
    attributeBindings: ['width', 'height'],

    didInsertElement: function didInsertElement() {
      var context = this.get('element').getContext('2d');
      var data = this.get('data');
      var type = _ember['default'].String.classify(this.get('type'));
      var options = _ember['default'].merge({}, this.get('options'));

      var chart = new Chart(context)[type](data, options);

      if (this.get('legend')) {
        var legend = chart.generateLegend();
        this.$().wrap("<div class='chart-parent'></div>");
        this.$().parent().append(legend);
      }

      this.set('chart', chart);
      this.addObserver('data', this, this.updateChart);
      this.addObserver('data.[]', this, this.updateChart);
      this.addObserver('options', this, this.updateChart);
    },

    willDestroyElement: function willDestroyElement() {
      if (this.get('legend')) {
        this.$().parent().children('[class$=legend]').remove();
      }

      this.get('chart').destroy();
      this.removeObserver('data', this, this.updateChart);
      this.removeObserver('data.[]', this, this.updateChart);
      this.removeObserver('options', this, this.updateChart);
    },

    updateChart: function updateChart() {
      var chart = this.get('chart');
      var data = this.get('data');
      var redraw = _emberCliChartChartDataUpdater['default'].create({
        data: data,
        chart: chart
      }).updateByType();

      if (redraw) {
        this.willDestroyElement();
        this.didInsertElement();
      } else {
        chart.update();
      }

      if (this.get('legend')) {
        this.$().parent().children('[class$=legend]').remove();
        var legend = chart.generateLegend();
        this.$().parent().append(legend);
      }
    }
  });
});