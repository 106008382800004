define("ember-material-lite/templates/components/mdl-radio", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.3.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "modules/ember-material-lite/templates/components/mdl-radio.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "type", "radio");
        dom.setAttribute(el1, "class", "mdl-radio__button");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "mdl-radio__label");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        if (this.cachedFragment) {
          dom.repairClonedNode(element0, [], true);
        }
        var element1 = dom.childAt(fragment, [2]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'id');
        morphs[1] = dom.createAttrMorph(element0, 'name');
        morphs[2] = dom.createAttrMorph(element0, 'value');
        morphs[3] = dom.createAttrMorph(element0, 'disabled');
        morphs[4] = dom.createAttrMorph(element0, 'checked');
        morphs[5] = dom.createMorphAt(element1, 0, 0);
        morphs[6] = dom.createMorphAt(element1, 1, 1);
        return morphs;
      },
      statements: [["attribute", "id", ["get", "_inputId", ["loc", [null, [1, 25], [1, 33]]]]], ["attribute", "name", ["get", "name", ["loc", [null, [1, 69], [1, 73]]]]], ["attribute", "value", ["get", "value", ["loc", [null, [1, 84], [1, 89]]]]], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [1, 103], [1, 111]]]]], ["attribute", "checked", ["get", "checked", ["loc", [null, [1, 124], [1, 131]]]]], ["content", "text", ["loc", [null, [2, 31], [2, 39]]]], ["content", "yield", ["loc", [null, [2, 39], [2, 48]]]]],
      locals: [],
      templates: []
    };
  })());
});